import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { PERMISSION } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const GoodReceiptNoteIndex = lazy(() => import('../../container/warehouse-inventory/good-receipt-note'));
const GoodReceiptNoteCreate = lazy(() => import('../../container/warehouse-inventory/good-receipt-note/Create'));
const GoodReceiptNoteCreateReturn = lazy(() => import('../../container/warehouse-inventory/good-receipt-note/CreateReturn'));
const GoodReceiptNoteShow = lazy(() => import('../../container/warehouse-inventory/good-receipt-note/Show'));

const GoodIssueNoteIndex = lazy(() => import('../../container/warehouse-inventory/good-issue-note'));
const GoodIssueNoteCreate = lazy(() => import('../../container/warehouse-inventory/good-issue-note/Create'));
const GoodIssueNoteShow = lazy(() => import('../../container/warehouse-inventory/good-issue-note/Show'));

const SparepartSalesIndex = lazy(() => import('../../container/warehouse-inventory/sparepart-sale'));
const SparepartSalesCreate = lazy(() => import('../../container/warehouse-inventory/sparepart-sale/Create'));

const StockOpnameIndex = lazy(() => import('../../container/warehouse-inventory/stock-opname'));
const StockOpnameShow = lazy(() => import('../../container/warehouse-inventory/stock-opname/Show'));

const InventoryIndex = lazy(() => import('../../container/warehouse-inventory/inventory'));
const InventoryShow = lazy(() => import('../../container/warehouse-inventory/inventory/Show'));

function WarehouseInventoryRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/good-receipt-note`} component={GoodReceiptNoteIndex} />
      {permissions.includes(PERMISSION.warehouseInventory.goodReceiptPoReceipt) && (
        <Route exact path={`${path}/good-receipt-note/:purchaseOrderId/create`} component={GoodReceiptNoteCreate} />
      )}
      {permissions.includes(PERMISSION.warehouseInventory.goodReceiptWoReceipt) && (
        <Route exact path={`${path}/good-receipt-note/:returnSparepartId/return/create`} component={GoodReceiptNoteCreateReturn} />
      )}
      <Route exact path={`${path}/good-receipt-note/:goodReceiptNoteId`} component={GoodReceiptNoteShow} />

      <Route exact path={`${path}/good-issue-note`} component={GoodIssueNoteIndex} />
      {permissions.includes(PERMISSION.warehouseInventory.modify) && <Route exact path={`${path}/good-issue-note/:workOrderPartId/create`} component={GoodIssueNoteCreate} />}
      <Route exact path={`${path}/good-issue-note/:goodIssueNoteId`} component={GoodIssueNoteShow} />

      <Route exact path={`${path}/sparepart-sale`} component={SparepartSalesIndex} />
      <Route exact path={`${path}/sparepart-sale/create`} component={SparepartSalesCreate} />
      {/* <Route exact path={`${path}/sparepart-sale/:sparepartSalesId`} component={SparepartSalesShow} /> */}
      {/* <Route exact path={`${path}/sparepart-sale/:sparepartSalesId/edit`} component={SparepartSalesEdit} /> */}

      <Route exact path={`${path}/stock-opname`} component={StockOpnameIndex} />
      <Route exact path={`${path}/stock-opname/:sparepartId`} component={StockOpnameShow} />

      <Route exact path={`${path}/inventory`} component={InventoryIndex} />
      <Route exact path={`${path}/inventory/:sparepartId`} component={InventoryShow} />
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/good-receipt-note`} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default WarehouseInventoryRoutes;
